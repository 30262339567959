<!--
 * @Author: 刘锦
 * @Date: 2020-11-04 09:48:32
 * @LastEditTime: 2022-06-10 16:07:28
 * @LastEditors: Seven
 * @Description: 
 * @FilePath: \workbench-front\src\components\order\payinfo-table.vue
-->
<template>
  <HqDrawer ref="drawer" title="付款明细" :visible.sync="visible" size="90%" :wrapperClosable="false" :show-close="false">
    <div class="modal-body">
      <div style="height: 10px"></div>
      <el-row :gutter="10">
        <el-col :span="10">
          <v-text-field
            hide-details="auto"
            readonly
            outlined
            :dense="true"
            :height="42"
            label="来源单号"
            :value="tableData.orderNo"
          ></v-text-field>
        </el-col>
        <el-col :span="10">
          <v-text-field
            hide-details="auto"
            readonly
            outlined
            :dense="true"
            :height="42"
            label="审核状态"
            :value="tableData.checkStatusText"
          ></v-text-field>
        </el-col>
      </el-row>
      <div class="extra-info" style="padding-top:18px">
        <el-row :gutter="10">
          <el-col :span="10">
            <v-text-field
              hide-details="auto"
              readonly
              outlined
              :dense="true"
              :height="42"
              label="商品名称"
              :value="`${tableData.goodsName}${tableData.goodsSpecName ? `(${tableData.goodsSpecName})` : ''}`"
            ></v-text-field>
          </el-col>
          <el-col :span="5">
            <v-text-field
              hide-details="auto"
              readonly
              outlined
              :dense="true"
              :height="42"
              label="已收金额"
              :value="tableData.receivedMoney + '元'"
            ></v-text-field>
          </el-col>
          <el-col :span="5">
            <v-text-field
              hide-details="auto"
              readonly
              outlined
              :dense="true"
              :height="42"
              label="核定可退金额"
              :value="tableData.refundMoney + '元'"
            ></v-text-field>
          </el-col>
        </el-row>
      </div>
      <!-- 财经组合套餐 -->
      <cjzhTable
        v-if="tableData.goodsTemplate === goodsTemplateEnum.cjzh"
        :tableData= tableData
        :extraInfo= extraInfo
        :moneyInfo= moneyInfo
        :titleList= titleList
      />
      <!-- 其他商品 -->
      <section class="listBox" v-else>
        <div v-for="(it, index2) in tableData.payments" :key="index2" class="listInfo">
          <el-table
            style="width: 100%"
            border
            highlight-current-row
            sortable
            header-cell-class-name="normal-table-header"
            :data="it.payItems"
          >
            <template v-for="item of columns">
              <el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'payTypeText'" :key="item.prop" :label="item.label"
                ><template v-slot:default="{ row }"
                  >{{ row.payTypeText }}</template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundTypeText'" :key="item.prop" :label="item.label"
                ><template v-slot:default="{ row }"
                  >{{ row.refundTypeText | empty('--') }}</template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundTime'" :key="item.prop" :label="item.label" :width="item.width"
                ><template v-slot:default="{ row }">
                  <span v-if="row.refundTime">{{ row.refundTime | formatDate("YYYY-MM-DD HH:mm:ss") }}</span>
                  <span v-else>--</span>
                </template>
                </el-table-column
              >
              <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'nickName' && tableData.payments[index2].payItems[0].payType == '0'"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.nickName | empty('--') }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'thirdPlatform' && (tableData.payments[index2].payItems[0].payType == '10' || tableData.payments[index2].payItems[0].payType == '18')"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.thirdPlatform }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'accountName' && (tableData.payments[index2].payItems[0].payType == '10' || tableData.payments[index2].payItems[0].payType == '18')"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.accountName }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'balanceAccount' && tableData.payments[index2].payItems[0].payType == '0'"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.balanceAccount }}</span></template
                ></el-table-column
              >

              <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'bankAccountName' && tableData.payments[index2].payItems[0].payType == '1'"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.bankAccountName }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'bank' && tableData.payments[index2].payItems[0].payType == '1'"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.bank }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'bankAccount' && tableData.payments[index2].payItems[0].payType == '1'"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.bankAccount }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'payNo'" :key="item.prop" :label="item.label" :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.payNo }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'orderNo'" :key="item.prop" :label="item.label" :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.orderNo }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'checkStatus'" :key="item.prop" :label="item.label" :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ orderShenMap[row.checkStatus] }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundedMoneySum'" :key="item.prop" :label="item.label" :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.refundedMoneySum }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'easNumber'" :key="item.prop" :label="item.label" :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.easNumber || '--' }}</span></template
                ></el-table-column>
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundProcess'" :key="item.prop" :label="item.label" :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ refundMap[row.refundProcess] }}</span></template
                ></el-table-column>
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundMoney'" :key="item.prop" :label="item.label" :width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.refundMoney | decimal2 }}</template
							></el-table-column>
              <!-- 贷款相关字段 -->
              <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'loanBankName' && tableData.payments[index2].payItems[0].payType == payTypeEnum.loan"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  >{{ row.loanBankName | empty('--')}}</template
                ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'loanCompanyName' && tableData.payments[index2].payItems[0].payType == payTypeEnum.loan"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  >{{ row.loanCompanyName | empty('--')}}</template
                ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'loanBankAccount' && tableData.payments[index2].payItems[0].payType == payTypeEnum.loan"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  >{{ row.loanBankAccount | empty('--')}}</template
                ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  v-else-if="item.prop === 'returnLoanTypeText' && [returnLoanType.all, returnLoanType.down].includes(tableData.payments[index2].payItems[0].returnLoanType)"
                  :key="item.prop"
                  :label="item.label"
                  :width="item.width"
                  ><template v-slot:default="{ row }"
                    >{{ row.returnLoanTypeText | empty('--')}}</template
                ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  v-else-if="item.prop === 'loanRefundTime' && [returnLoanType.all, returnLoanType.down].includes(tableData.payments[index2].payItems[0].returnLoanType)"
                  :key="item.prop"
                  :label="item.label"
                  :width="item.width"
                  ><template v-slot:default="{ row }"
                    >{{ row.loanRefundTime | formatDate2("YYYY-MM-DD")}}</template
                ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  v-else-if="item.prop === 'loanReturnedMoney' && [returnLoanType.all, returnLoanType.down].includes(tableData.payments[index2].payItems[0].returnLoanType)"
                  :key="item.prop"
                  :label="item.label"
                  :width="item.width"
                  ><template v-slot:default="{ row }"
                    >{{ row.loanReturnedMoney | empty('--')}}</template
                ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  v-else-if="item.prop === 'reduceLoanMoney' &&  returnLoanType.down === tableData.payments[index2].payItems[0].returnLoanType"
                  :key="item.prop"
                  :label="item.label"
                  :width="item.width"
                  ><template v-slot:default="{ row }"
                    >{{ row.reduceLoanMoney | empty('--')}}</template
                ></el-table-column>
                <!-- 贷款 end -->
            </template>
          </el-table>
          <el-table
            style="width: 100%; margin-top: 30px"
            border
            highlight-current-row
            sortable
            header-cell-class-name="normal-table-header"
            :header-cell-style="{ background: '#F5F7FB' }"
            :data="it.inoutProjects"
            fit
          >
            <template v-for="item of dropColumns">
              <el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'performanceRatio'" :key="item.prop" :label="item.label"
                ><template v-slot:default="{ row }"
                  >
                  <p v-if="row.inoutType == 0">{{ row.performanceRatio || 0 }}.00%</p>
                  <p v-else>- -</p>
                </template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'completionRatio'" :key="item.prop" :label="item.label"
                ><template v-slot:default="{ row }"
                  ><p v-if="row.inoutType == 0">{{ row.completionRatio || 0 }}.00%</p>
                  <p v-else>- -</p>
                  </template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'receivableMoney'" :key="item.prop" :label="item.label"
                ><template v-slot:default="{ row }">
                  <p v-if="row.inoutType == 0">{{ row.receivableMoney }}</p>
                  <p v-else>- -</p>
                </template></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'receivedMoney'" :key="item.prop" :label="item.label"
                ><template v-slot:default="{ row }">
                  <p v-if="row.inoutType == 0">{{ row.receivedMoney }}</p>
                  <p v-else>- -</p>
                </template></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'type'" :key="item.prop" :label="item.label"
                ><template v-slot:default="{ row }">
                  <p v-if="row.inoutType == 0">{{ row.type }}</p>
                  <p v-else>- -</p>
                </template></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'calculatePerformance'" :key="item.prop" :label="item.label"
                ><template v-slot:default="{ row }">
                  <p v-if="row.inoutType == 0">{{ row.calculatePerformance }}</p>
                  <p v-else>- -</p>
                </template></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'periodIncome'" :key="item.prop" :label="item.label" :width="item.width" :cell-style="{width: '160px'}"
                ><template v-slot:default="{ row }">
                  <p v-if="row.inoutType == 0">{{ row.periodIncome }}</p>
                  <p v-else>- -</p>
                </template></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'inoutProportion'" :key="item.prop" :label="item.label"
                ><template v-slot:default="{ row }">
                  <p v-if="row.inoutType == 0">{{ row.inoutProportion || 0 }}%</p>
                  <p v-else>- -</p>
                </template></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true"
                v-else
                :key="item.prop"
                :label="item.label"
                :prop="item.prop"
                :fixed="item.fixed"
                :width="item.width || '160px'"
              >
                <template v-slot:default="{row}">
                  <span v-if="['deductionMoney'].includes(item.prop)">{{ row[item.prop] | empty(0) }}</span>
                  <span v-else>{{ row[item.prop] }}</span>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </section>
    </div>
    <div slot="footer">
      <el-button type="primary" @click="onClose">确定</el-button>
    </div>
    <!-- <template slot="footer"> -->
    <!-- <div class="modal-footer">
        <el-button type="primary" @click="onClose">确定</el-button>
      </div> -->
    <!-- </template> -->
    <!-- </el-dialog> -->
  </HqDrawer>
</template>

<script>
import { allPayMap, payTypeMap, payStatusMap, refundTypeMap, orderShenMap, refundMap, goodsTemplateEnum, payTypeEnum } from "../constants"
import cjzhTable from './drop-payinfo/cjzh-table.vue'
export default {
  components: {
    cjzhTable
  },
  props: {
    visible: Boolean,
    tableData: Object,
    columns: Array,
    dropColumns: Array,
    extraInfo: Object,
    moneyInfo: Array,
    titleList: Array,
  },
  data() {
    return {
      allPayMap,
      payTypeMap,
      payStatusMap,
      refundTypeMap,
      orderShenMap,
      refundMap,
      payType: {
        0: "学员余额",
        1: "银行账户",
        2: "贷款",
      },
      goodsTemplateEnum,
      payTypeEnum,
      // 退贷类型（1全部退款、2降贷）【贷款相关】
      returnLoanType: {
        all: 1,
        down: 2
      }
    }
  },
  methods: {
    onClose() {
      this.$refs.drawer.close()
      this.$emit("update:visible", false)
    },
    init() {
      this.$refs.drawer.init()
    }
  },
}
</script>

<style lang="scss" scoped>
.listBox {
  height: 75vh;
  overflow: scroll;
  padding-bottom: 40px;
  .listInfo:not(:last-child) {
    border-bottom: 2px solid #d4d3d3;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}
::v-deep .el-dialog {
  width: 60%;
}
::v-deep .el-dialog__header {
  padding-top: 0;
  padding-bottom: 0;
  height: 60px;
}
::v-deep .el-dialog__body {
  padding: 30px 0;
  border-top: 1px solid #e1e4eb;
  border-bottom: 1px solid #e1e4eb;
}
::v-deep .el-dialog__footer {
  height: 84px;
  padding: 0;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  > h2 {
    font-size: 16px;
    font-weight: 700;
    color: #333;
  }
  > i {
    color: #9bacbf;
    font-size: 14px;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      transform: rotate(180deg);
    }
  }
}
.modal-body {
  // padding: 0 24px;
  width: 94%;
  margin: 0 auto;
  height: 100%;
  ::v-deep .el-input__inner {
    width: 504px;
    height: 36px;
  }
  .extra-info {
    // display: flex;
    // justify-content: space-between;
    margin-top: 5px;
    .extraInfoLine2 {
      display: flex;
      font-size: 14px;
      color: #333;
      justify-content: space-between;
      .right {
        display: flex;
        p {
          margin-left: 20px;
        }
        span {
          color: red;
        }
      }
    }
    margin-bottom: 18px;
    .goods {
      display: flex;
    }
    .money {
    }

    > p:first-child {
      line-height: 20px;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      margin-bottom: 8px;
    }

    > p:last-child {
      line-height: 16px;
      font-size: 12px;
      font-weight: 700;
      color: #999999;
    }
  }
}
.modal-footer {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  text-align: center;
  margin-top: 20px;
  height: 100%;
}
</style>

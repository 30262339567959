<template>
	<div class="cjzh-container">
		<div v-for="(item, index) in tableData.payments" :key="index" class="item">
			<div v-for="(payItem, index2) in item.payItems" :key="index2">
				<!-- 付款笔数表 -->
				<el-table
					style="width: 100%"
					border
					sortable
					header-cell-class-name="normal-table-header"
					:data="[payItem]"
				>
					<template v-for="item of columns">
						<el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'payType'" :key="item.prop" :label="item.label"
							><template v-slot:default="{ row }">
                <!-- {{ payType[row.payType] | empty('--')}} -->
                {{ row.payTypeText }}
                </template
							></el-table-column
						>
						<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundTime'" :key="item.prop" :label="item.label" :width="item.width"
							><template v-slot:default="{ row }">
								<span v-if="row.refundTime">{{ row.refundTime | formatDate2("YYYY-MM-DD HH:mm:ss") }}</span>
								<span v-else>--</span>
							</template>
							</el-table-column
						>
						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'nickName' && tableData.payments[index].payItems[index2].payType == payTypeEnum.balance"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.nickName | empty('--')}}</template
							></el-table-column
						>
            <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'thirdPlatform' && (tableData.payments[index].payItems[0].payType == '10' || tableData.payments[index2].payItems[0].payType == '18')"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.thirdPlatform }}</span></template
                ></el-table-column
              >
              <el-table-column :show-overflow-tooltip="true"
                v-else-if="item.prop === 'accountName' && (tableData.payments[index].payItems[0].payType == '10' || tableData.payments[index2].payItems[0].payType == '18')"
                :key="item.prop"
                :label="item.label"
                :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.accountName }}</span></template
                ></el-table-column
              >
						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'balanceAccount' && tableData.payments[index].payItems[index2].payType == payTypeEnum.balance"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.balanceAccount }}</template
							></el-table-column
						>
						<!-- 贷款 -->
						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'returnLoanTypeText' && [returnLoanType.all, returnLoanType.down].includes(tableData.payments[index].payItems[index2].returnLoanType)"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.returnLoanTypeText | empty('--')}}</template
						></el-table-column>
						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'loanRefundTime' && [returnLoanType.all, returnLoanType.down].includes(tableData.payments[index].payItems[index2].returnLoanType)"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.loanRefundTime | formatDate2("YYYY-MM-DD")}}</template
						></el-table-column>
						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'loanReturnedMoney' && [returnLoanType.all, returnLoanType.down].includes(tableData.payments[index].payItems[index2].returnLoanType)"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.loanReturnedMoney | empty('--')}}</template
						></el-table-column>
						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'reduceLoanMoney' && returnLoanType.down === tableData.payments[index].payItems[index2].returnLoanType"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.reduceLoanMoney | empty('--')}}</template
						></el-table-column>
						<!-- 贷款 end -->

						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'installmentType' && tableData.payments[index].payItems[index2].installmentType"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.installmentTypeText | empty('--')}}</template
							></el-table-column
						>
						<!-- 贷款相关字段 -->
						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'loanBankName' && tableData.payments[index].payItems[index2].payType == payTypeEnum.loan"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.loanBankName | empty('--')}}</template
							></el-table-column>
							<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'loanCompanyName' && tableData.payments[index].payItems[index2].payType == payTypeEnum.loan"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.loanCompanyName | empty('--')}}</template
							></el-table-column>
							<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'loanBankAccount' && tableData.payments[index].payItems[index2].payType == payTypeEnum.loan"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.loanBankAccount | empty('--')}}</template
							></el-table-column>
							<!-- 贷款 end -->

						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'bankAccountName' && tableData.payments[index].payItems[index2].payType == payTypeEnum.bank"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.bankAccountName }}</template
							></el-table-column
						>
						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'bank' && tableData.payments[index].payItems[index2].payType == payTypeEnum.bank"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.bank }}</template
							></el-table-column
						>
						<el-table-column :show-overflow-tooltip="true"
							v-else-if="item.prop === 'bankAccount' && tableData.payments[index].payItems[index2].payType == payTypeEnum.bank"
							:key="item.prop"
							:label="item.label"
							:width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.bankAccount | empty('--')}}</template
							></el-table-column
						>
						<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'payNo'" :key="item.prop" :label="item.label" :width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.payNo }}</template
							></el-table-column
						>
						<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'orderNo'" :key="item.prop" :label="item.label" :width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.orderNo }}</template
							></el-table-column
						>
						<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'checkStatus'" :key="item.prop" :label="item.label" :width="item.width"
							><template v-slot:default="{ row }"
								>{{ orderShenMap[row.checkStatus] | empty('--')}}</template
							></el-table-column
						>
						<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundMoney'" :key="item.prop" :label="item.label" :width="item.width"
							><template v-slot:default="{ row }"
								>{{ row.refundMoney | decimal2 }}</template
							></el-table-column
						>
            <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'easNumber'" :key="item.prop" :label="item.label" :width="item.width"
                ><template v-slot:default="{ row }"
                  ><span>{{ row.easNumber || '--' }}</span></template
                ></el-table-column>
						<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundProcess'" :key="item.prop" :label="item.label" :width="item.width"
							><template v-slot:default="{ row }"
								><span>{{ refundMap[row.refundProcess] | empty('--') }}</span></template
							></el-table-column
						>
						<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundTypeText'" :key="item.prop" :label="item.label" :width="item.width"
							><template v-slot:default="{ row }"
								><span>{{ row.refundTypeText | empty('--') }}</span></template
							></el-table-column
						>
					</template>
				</el-table>
				<!-- 商品信息 -->
				<div v-if="payItem.payGoodsList">
					<div v-for="(payGoodItem, index3) in payItem.payGoodsList" :key="index3">
						<el-table
							style="width: 100%"
							border
							sortable
							header-cell-class-name="normal-table-header"
              :header-cell-style="{ background: '#F5F7FB' }"
							:data="[payGoodItem]"
						>
							<template v-for="item of goodColumns">
								<el-table-column :show-overflow-tooltip="true" :key="item.prop" :label="item.label" :width="item.width">
									<template v-slot:default="{ row }">
										<span v-if="['goodsSpecName'].includes(item.prop)">{{ row[item.prop] |empty('--') }}</span>
										<span v-else-if="['receivableMoney', 'receivedMoney', 'deductionMoney', 'refundMoney', 'refundedMoneySum'].includes(item.prop)">{{ row[item.prop] | decimal2 }}</span>
										<span v-else>{{ row[item.prop] }}</span>	
									</template>
								</el-table-column>
							</template>
						</el-table>
						<!-- 收支项目 -->
						<div v-if="payGoodItem.inoutProjects" class="inout_row">
							<div v-for="(inout, index4) in payGoodItem.inoutProjects" :key="index4">
								<el-table
									style="width: 100%"
									border
									sortable
									header-cell-class-name="normal-table-header"
									:data="payGoodItem.inoutProjects"
									v-if="index4 === 0"
								>
									<template v-for="item of dropColumns">
										<el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'performanceRatio'" :key="item.prop" :label="item.label"
										><template v-slot:default="{ row }"
											>
											<span v-if="row.inoutType == 0">{{ row.performanceRatio || 0 }}.00%</span>
											<span v-else>- -</span>
										</template
										></el-table-column
									>
									<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'completionRatio'" :key="item.prop" :label="item.label"
										><template v-slot:default="{ row }"
											><span v-if="row.inoutType == 0">{{ row.completionRatio || 0 }}.00%</span>
											<span v-else>- -</span>
											</template
										></el-table-column
									>
									<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'receivableMoney'" :key="item.prop" :label="item.label"
										><template v-slot:default="{ row }">
											<span v-if="row.inoutType == 0">{{ row.receivableMoney | decimal2 }}</span>
											<span v-else>- -</span>
										</template></el-table-column
									>
									<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'receivedMoney'" :key="item.prop" :label="item.label"
										><template v-slot:default="{ row }">
											<span v-if="row.inoutType == 0">{{ row.receivedMoney | decimal2}}</span>
											<span v-else>- -</span>
										</template></el-table-column
									>
									<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'type'" :key="item.prop" :label="item.label"
										><template v-slot:default="{ row }">
											<span v-if="row.inoutType == 0">{{ row.type }}</span>
											<span v-else>- -</span>
										</template></el-table-column
									>
									<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'calculatePerformance'" :key="item.prop" :label="item.label"
										><template v-slot:default="{ row }">
											<span v-if="row.inoutType == 0">{{ row.calculatePerformance }}</span>
											<span v-else>- -</span>
										</template></el-table-column
									>
									<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'periodIncome'" :key="item.prop" :label="item.label" width="150px"
										><template v-slot:default="{ row }">
											<span v-if="row.inoutType == 0">{{ row.periodIncome }}</span>
											<span v-else>- -</span>
										</template></el-table-column
									>
									<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'inoutProportion'" :key="item.prop" :label="item.label"
										><template v-slot:default="{ row }">
											<span v-if="row.inoutType == 0">{{ row.inoutProportion || 0 }}%</span>
											<span v-else>- -</span>
										</template></el-table-column
									>
									<el-table-column :show-overflow-tooltip="true"
										v-else
										:key="item.prop"
										:label="item.label"
										:prop="item.prop"
										:fixed="item.fixed"
										:width="item.width || '160px'"
									>
										<template v-slot:default="{row}">
											<span v-if="['deductionMoney', 'refundableMoney', 'refundedMoneySum'].includes(item.prop)">{{ row[item.prop] | decimal2 }}</span>
											<span v-else>{{ row[item.prop] }}</span>
										</template>
									</el-table-column>
									</template>
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// 财经组合套餐付款明细表格
import { installmentTypeMap, orderShenMap, refundMap, refundTypeMap } from '../../constants'

export default {
	props: {
		tableData: Object,
    extraInfo: Object,
    moneyInfo: Array,
    titleList: Array,
	},
	data(){
	return {
		refundMap,
		refundTypeMap,
		orderShenMap,
		columns: [
			{ label: "付款笔数", prop: "payNo" },
			{ label: "付款时间", prop: "refundTime", width: "160px" },
			{ label: "付款金额", prop: "refundMoney" },
			{ label: "降贷金额", prop: "reduceLoanMoney" },
			{ label: "付款类型", prop: "refundTypeText" },
			{ label: "付款方式", prop: "payType" },
			{ label: "退贷类型", prop: "returnLoanTypeText" },
			{ label: "预计退贷日期", prop: "loanRefundTime", width: "120px" },
			{ label: "学员已还金额", prop: "loanReturnedMoney", width: "120px" },
			{ label: "贷款平台", prop: "installmentType" },
			{ label: "平台开户名", prop: "loanCompanyName", width: "120px" },
			{ label: "平台开户行", prop: "loanBankName", width: "120px" },
			{ label: "平台银行账号", prop: "loanBankAccount", width: "120px" },
      { label: "第三方", prop: "thirdPlatform" },
        { label: "学员手机", prop: "accountName" },
			{ label: "学员姓名", prop: "nickName" },
			{ label: "学员账号", prop: "balanceAccount" },
			{ label: "学员开户名", prop: "bankAccountName" },
			{ label: "学员户行账号", prop: "bankAccount" },
			{ label: "学员开户行", prop: "bank" },
      { label: "EAS单号", prop: "easNumber", width: "120px" },
			{ label: "付款", prop: "refundProcess" },
		],
		goodColumns: [
			{ label: "商品ID", prop: "goodsId"},
			{ label: "商品", prop: "goodsName"},
			{ label: "班型", prop: "goodsSpecName"},
			{ label: "应收金额", prop: "receivableMoney"},
			{ label: "已收金额", prop: "receivedMoney"},
			{ label: "扣费金额", prop: "deductionMoney"},
			{ label: "核定可退金额", prop: "refundMoney"},
			{ label: "已退金额", prop: "refundedMoneySum"}
		],
		dropColumns: [
			{ label: "收支项目编码", prop: "code",fixed:true },
			{ label: "收支项目名称", prop: "orderInoutProjectName", fixed:true },
			{ label: "收支占比", prop: "inoutProportion" },
			{ label: "应收金额", prop: "receivableMoney" },
			{ label: "已收金额", prop: "receivedMoney",  },
			{ label: "扣费金额", prop: "deductionMoney", width: '120px' },
			{ label: "核定可退金额", prop: "refundableMoney" },
			{ label: "已退金额", prop: "refundedMoneySum" },
			{ label: "绩效比例", prop: "performanceRatio" },
			{ label: "完成率比例", prop: "completionRatio" },
			{ label: "收支项目类型", prop: "type" },
			{ label: "是否计算绩效", prop: "calculatePerformance" },
			{ label: "计算收入方式", prop: "periodIncome" },
		],
		payType: {
			0: "学员余额",
			1: "银行账户",
			2: "贷款",
		},
		payTypeEnum:{
			balance: 0,//学员余额
			bank: 1,//银行账户
			loan: 2,//贷款
		},
		// 退贷类型（1全部退款、2降贷）【贷款相关】
		returnLoanType: {
			all: 1,
			down: 2
		}
	}
	}
	
}
</script>

<style lang="scss">
.el-table{
	margin-bottom: 10px;
}
.inout_row{
	padding-bottom: 18px;
}
.cjzh-container{
	padding-bottom: 35px;
	.item{
		margin-bottom: 15px;
	}
}
</style>